<template>
  <mobile-screen :header="true" screen-class="gray-bg icon-app1 about">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("about", "about", "about") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li
        class="clebex-item-section-item full-right-underline no-decoration-link"
        v-for="application in applications"
        :key="application.id"
      >
        <div class="clebex-item-content-wrapper cursor-pointer">
          <span
            class="label clebex-item-label"
            @click="goToAppVersions(application)"
            >{{ application.name }}
            {{
              application.latest_version
                ? `(${application.latest_version})`
                : ""
            }}</span
          >
          <span class="follow-up-icons" @click="goToAppVersions(application)">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "About",
  data() {
    return {
      processing: false,
      show: false,
      subdomain: getSubdomain()
    };
  },
  created() {
    this.getAppInfo();
  },
  computed: {
    ...mapState("applications", ["applications", "selectedApplication"])
  },
  methods: {
    ...mapActions("applications", [
      "getApplications",
      "setSelectedApplication",
      "getAppVersions",
      "setSelectedAppVersions"
    ]),
    getAppInfo() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getApplications()
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.processing = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    goToAppVersions(app) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.setSelectedApplication(app);
      this.getAppVersions(app.id)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.selectedApplication !== null
            ? this.$router.push({ name: "r_application-versions" })
            : "";
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
